import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import VideoEditor from './pages/video_editor';
function App() {
  return (
    <>
      <div className="container-fluid">
        <VideoEditor />
      </div>
      <div className='footer'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col'>
              &nbsp;&copy;2024&nbsp;AJoesoft &nbsp;AI0
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
