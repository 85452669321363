import React from "react";

class VideoEditor extends React.Component {

    render() {
        return <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <h1 className="display-4 m-4">AI0 工具集</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <div className="card m-4">
                            <div className="card-header">
                                <i className="bi bi-film"></i>
                                视频编辑
                            </div>
                            <div className="card-body">
                                <div className="input-group  mb-3">
                                    <input className="form-control" type="file" />
                                    <button className="btn btn-primary">上传视频</button>
                                </div>
                                <div className="input-group  mb-3">
                                    <input className="form-control" type="file" />
                                    <button className="btn btn-primary">上传封面</button>
                                </div>

                                <div className="input-group  mb-3">
                                    <input className="form-control" type="file" />
                                    <button className="btn btn-primary">上传水印</button>
                                </div>
                                <div className="input-group  mb-3">
                                    <input className="form-control" type="text" placeholder="宽" />
                                    <input className="form-control" type="text" placeholder="高" />
                                    <select className="form-control">
                                        <option>按比例</option>
                                        <option>左上</option>
                                        <option>居中</option>
                                        <option>缩放居中</option>
                                    </select>
                                    <button className="btn btn-primary">缩放</button>
                                </div>

                                <div class="btn-group mb-3" role="group" aria-label="Basic example">
                                    <button className="btn btn-secondary">格式转换</button>
                                    <button className="btn btn-primary">M3U8</button>
                                    <button className="btn btn-primary">WEBM</button>
                                    <button className="btn btn-primary">MP4</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="card m-4">
                            <div className="card-header">
                                Markdown转换
                            </div>
                            <div className="card-body">
                                <div className="input-group  mb-3">
                                    <input className="form-control" type="file" />
                                    <button className="btn btn-primary">上传文件</button>
                                </div>
                                <div class="btn-group mb-3" role="group" aria-label="Basic example">
                                    <button className="btn btn-secondary">格式转换</button>
                                    <button className="btn btn-primary">WORD</button>
                                    <button className="btn btn-primary">Docxy</button>
                                    <button className="btn btn-primary">HTML</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="card m-4">
                            <div className="card-header">
                                AsciiDoc转换
                            </div>
                            <div className="card-body">
                                <div className="input-group  mb-3">
                                    <input className="form-control" type="file" />
                                    <button className="btn btn-primary">上传文件</button>
                                </div>
                                <div class="btn-group mb-3" role="group" aria-label="Basic example">
                                    <button className="btn btn-secondary">格式转换</button>
                                    <button className="btn btn-primary">WORD</button>
                                    <button className="btn btn-primary">PDF</button>
                                    <button className="btn btn-primary">HTML</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <div className="card m-4">
                            <div className="card-header">
                                <i className="bi bi-card-image"></i>
                                FAVICON转换
                            </div>
                            <div className="card-body">
                                <div className="input-group  mb-3">
                                    <input className="form-control" type="file" />
                                    <button className="btn btn-primary">上传图标</button>
                                </div>

                                <div className="input-group  mb-3">
                                    <input className="form-control" type="text" placeholder="宽" />
                                    <input className="form-control" type="text" placeholder="高" />
                                    <select className="form-control">
                                        <option>24X</option>
                                        <option>48X</option>
                                        <option>128X</option>
                                        <option>512X</option>
                                    </select>
                                    <button className="btn btn-primary">转换</button>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="card m-4">
                            <div className="card-header">
                                图片去背景
                            </div>
                            <div className="card-body">
                                <div className="input-group  mb-3">
                                    <input className="form-control" type="file" />
                                    <button className="btn btn-primary">上传文件</button>
                                </div>
                                <div class="btn-group mb-3" role="group" aria-label="Basic example">
                                    <button className="btn btn-secondary">格式转换</button>
                                    <button className="btn btn-primary">WORD</button>
                                    <button className="btn btn-primary">Docxy</button>
                                    <button className="btn btn-primary">HTML</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="card m-4">
                            <div className="card-header">
                                <i className="bi bi-file-binary"></i>
                                数字转换
                            </div>
                            <div className="card-body">
                                <div className="input-group  mb-3">
                                    <input className="form-control" type="text" />
                                    <button>&gt;</button>
                                    <input className="form-control" type="text" />
                                </div>
                                <div class="btn-group mb-3" role="group" aria-label="Basic example">
                                    <button className="btn btn-secondary">格式转换</button>
                                    <button className="btn btn-primary">2=10</button>
                                    <button className="btn btn-primary">2=16</button>
                                    <button className="btn btn-primary">10=2</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <div className="card m-4">
                            <div className="card-header">
                                <i className="bi bi-qr-code"></i>
                                二维码生成
                            </div>
                            <div className="card-body">
                                <div className="input-group  mb-3">
                                    <input className="form-control" placeholder="生成二维码的内容" type="text" />
                                    <button className="btn btn-primary">生成</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="card m-4">
                            <div className="card-header">
                                图片生成
                            </div>
                            <div className="card-body">
                                <div className="input-group  mb-3">
                                    <input className="form-control" placeholder="生成图片描述文字" type="text" />
                                    <button className="btn btn-primary">生成</button>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="card m-4">
                            <div className="card-header">
                                <i className="bi bi-file-binary"></i>
                                文字转语音
                            </div>
                            <div className="card-body">
                                <div className="input-group  mb-3">
                                <input className="form-control" placeholder="转换音频的文字" type="text" />
                                <button className="btn btn-primary">生成</button>
                                </div>
                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }
}

export default VideoEditor;